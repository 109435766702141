

.App {
    width: 100%;
    display: flex;
    height: 53vh;
    justify-content: space-around;
    flex-direction: column;
    background: #ffff;
    border-radius: 10px;
}

form {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
}

input {
    background: #f9f9f9;
    padding: 10px;
    width: 100%;
    border: none;
    margin-bottom: 20px;
    font-size: 14px;
    color: rgb(0, 0, 0);
    outline: none;
    font-weight: 300;
}


.rccs__card--unknown > div {
    background: #75c9a5;
}